<template >
<div class="bg-gray-850 text-white">


  <div class="bg-gray-950 flex flex-col justify-center mx-auto py-16 w-full">
    <div class="flex  justify-between mx-12 md:mx-96">
      <input
        class=" w-42  md:w72 border-b-2 border-gray-800 text-gray-200 bg-transparent focus:border-gray-600 transition-all focus:outline-none "
        type="text"
        v-model="namesInput"
        @keyup.enter="addNames(namesInput)"
        placeholder="Nom des joueurs"
      />
      <div
        class="
          bg-gray-700
          text-white
          w-8
          h-8
          rounded
          text-xl 
          text-gray-200
          flex
          justify-center
          items-center
          cursor-pointer
          hover:bg-gray-850 
          transition-all
        "
        v-on:click="addNames(namesInput)"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
</svg>
      </div>
    </div>
    <div class="flex mt-16 mx-12 justify-between md:mx-96">
      <select class="w-12 sm:w-24 md:w-72  rounded pl-2 bg-transparent text-gray-200 focus:outline-none cursor-pointer focus:text-gray-500" name="" id="" v-model="teamInput">
        <option disabled value="2">Choisissez le nombre d'équipe</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
        <option>6</option>
        <option>7</option>
        <option>8</option>
        <option>9</option>
      </select>
      <div
        class="transition-all bg-gray-700 text-gray-100  rounded cursor-pointer hover:bg-gray-850 px-4 py-2 md:px-8 md:py-4  text-sm md:text"
        v-on:click="randomize(teamInput)"
      >
        Randomize
      </div>
    </div>
  </div>
  <div class="flex my-12 flex-wrap mx-12 md:mx-56  ">
    <div
      class="w-56 mr-12 mb-12 rounded shadow cursor-default bg-gray-950"
      v-for="(team) in teams"
      :key="team.id"
    >
      <div
        class="
          text-center text-white
          font-bold
          text-xl
          p-4
          rounded-t
        "
        :class="[this.colorsTeams[Math.floor(Math.random() * this.colorsTeams.length)]]"
      >
        {{ team.name }}
      </div>
      <div class=" bg-gray-950 rounded-b">
        <div
          class=" pl-12 py-2"
          v-for="player in team.players"
          :key="player.id"
        >
          {{ player }}
        </div>
      </div>
    </div>
  </div>
  <div class="mx-12 md:mx-56">
    <div class=" transition-all bg-gray-950 rounded flex items-center mb-12 hover:shadow-lg  justify-between px-12 py-4 " v-for="(name, index) in names" :key="name.id">
      <div class=" text-xl">

      {{ name }}
      </div>
      <div class="" v-on:click="removePlayer(index)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="bi bi-x-lg fill-current text-gray-600 hover:text-red-500 cursor-pointer w-6 h-6 font-bold transition"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
          />
          <path
            fill-rule="evenodd"
            d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
          />
        </svg>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
let names = [];
let teams = [];
let namesInput = "";
let teamInput=2;
let colorsTeams = ["bg-purple-350","bg-green-350","bg-red-350","bg-yellow-350","bg-yellow-450","bg-blue-350","bg-pink-350"]
// let colorsTeams=[{pC:"from-purple-350",sC:"to-purple-350"},{pC:"from-green-350",sC:"to-green-350"},{pC:"from-red-350",sC:"to-red-350"},{pC:"from-yellow-350",sC:"to-yellow-350"}];
export default {
  name: "App",
  data: function () {
    return {
      names,
      teams,
      namesInput,
      teamInput,
      colorsTeams
    };
  },
  methods: {
    shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    },
    addNames(name) {
      if (name != "") {
        this.names.push(name);
        this.namesInput = "";
      }
    },
    initiateTeams(numberTeams) {
      for (let i = 1; i <= numberTeams; i++) {
        this.teams.push({ name: "Equipe " + i, players: [] });
      }
    },
    randomize(numberTeams) {
      //clear teams array
      this.teams = [];

      console.log(this.teams.length%this.colorsTeams.length)
      console.log(this.colorsTeams.length)

      //initiate the teeams
      for (let i = 1; i <= numberTeams; i++) {
        this.teams.push({ name: "Equipe " + i, players: [] });
      }

      //shuffle the players
      let shuffledNames = JSON.parse(JSON.stringify(this.names));
      this.shuffle(shuffledNames);

      //distribute the players
      let i = shuffledNames.length;
      let j = 0;

      while (i > 0) {
        if (j < this.teams.length) {
          this.teams[j].players.push(shuffledNames[i - 1]);
          j += 1;
        } else {
          j = 0;
          this.teams[j].players.push(shuffledNames[i - 1]);
          j += 1;
        }
        i -= 1;
      }
    },
    removePlayer(i) {
      this.names.splice(i, 1);
    },
  },
};
</script>
